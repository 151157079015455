import { jwtDecode } from 'jwt-decode'
import { defineStore } from 'pinia'

export interface AuthState {
  token?: string
  name?: string
  id?: string
}
const useAuthStore = defineStore('fluxabeeAuth', () => {
  const auth = ref<AuthState>({})
  const getToken = computed(() => auth.value?.token || '')
  const getName = computed(() => auth.value?.name || '')
  const isLogged = computed(() => {
    return !!auth.value?.token
  })
  function login(authToken: string) {
    try {
      const decoded: AuthState = jwtDecode(String(authToken))
      auth.value = {
        token: authToken,
        name: decoded?.name ?? '',
        id: decoded?.id ?? ''
      }
    } catch (err) {
      auth.value = {}
      console?.error?.('not decoded jwt', err, authToken)
    }
  }
  function logout() {
    const { /* getAxiosInstance, */ getAppGlobalProperties } = appUtilsPlugin
    // const { getFluxabeeApiUrl } = siteUtils
    const router = getAppGlobalProperties()?.$router
    // getAxiosInstance()?.get(getFluxabeeApiUrl('logout')).catch((err: Error) => {
    //   // eslint-disable-next-line no-console
    //   console?.error(err)
    // })
    auth.value = {}
    router?.push('/login')
  }
  return { auth, getToken, getName, login, logout, isLogged }
}, { persist: !import.meta.env.SSR })

export { useAuthStore }
