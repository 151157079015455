import type { SiteRoutesConfigurator } from '@/router/init/SiteRoutesConfigurator'

export class SiteRoutesGroup {
  isRemoved = false
  isVisibleInMenu = true
  showOnlyRoutes = false
  id = ''
  label = ''
  menuLabelClass = ''
  icon = ''
  iconClass = ''
  menuPriority = 0
  routes: SiteRoutesConfigurator[] = []

  constructor(id: string, label = '', icon = '') {
    this.id = `${id}-group`
    this.setLabel(label).setIcon(icon)
  }

  setIsRemoved(removed = true) {
    this.isRemoved = removed
    return this
  }

  setIsVisibleInMenu(visible = true) {
    this.isVisibleInMenu = visible
    return this
  }

  setShowOnlyRoutes(showOnlyRoutes = false) {
    this.showOnlyRoutes = showOnlyRoutes
    return this
  }

  setLabel(label: string) {
    this.label = label
    return this
  }

  setMenuLabelClass(menuLabelClass: string) {
    this.menuLabelClass = menuLabelClass
    return this
  }

  setIcon(icon: string) {
    this.icon = icon
    return this
  }

  setIconClass(iconClass: string) {
    this.iconClass = iconClass
    return this
  }

  setMenuPriority(priority: number) {
    this.menuPriority = priority
    return this
  }

  setRoutes(routes: SiteRoutesConfigurator[]) {
    this.routes = routes.map((route) => {
      route.setMenuIdGroup(this.id)
      return route
    })
    return this
  }

  pushRoutes(routes: SiteRoutesConfigurator[]) {
    this.routes.push(...routes)
    return this
  }
}
