<script setup lang="ts">
import type { UploadProps } from 'element-plus'
import { UploadFilled } from '@element-plus/icons-vue'

const handleChange: UploadProps['onChange'] = (uploadFile, uploadFiles) => {
  // eslint-disable-next-line no-console
  console.log(uploadFile, uploadFiles, 'handleChange')
}
</script>

<template>
  <div v-if="$route.name === 'dashboard'">
    <fbee-form-component>
      <fbee-form-fields-group>
        <el-upload
          class="w-full"
          drag
          action="http://localhost:8080/test-voice/"
          list-type="picture"
          :on-change="handleChange"
        >
          <el-icon class="el-icon--upload">
            <upload-filled />
          </el-icon>
          <div class="el-upload__text">
            Drop file here or <em>click to upload</em>
          </div>
          <template #tip>
            <div class="el-upload__tip">
              jpg/png files with a size less than 500kb
            </div>
          </template>
        </el-upload>
      </fbee-form-fields-group>
    </fbee-form-component>
  </div>
</template>

<style scoped>
</style>
