import type { SiteRoutesConfigurator } from '@/router/init/SiteRoutesConfigurator'
import type { SiteRoutesGroup } from '@/router/init/SiteRoutesGroup'
import { SiteRoutesConfigurator as RoutesConfigurator } from '@/router/init/SiteRoutesConfigurator'
import { SiteRoutesGroups } from '@/router/init/SiteRoutesGroups'

export class SiteRotesReader {
  private static _list: SiteRoutesConfigurator[] = []
  private static _entityMap: Record<string, number> = {}
  private static _namesMap: Record<string, number> = {}
  private static _groupsMap: Record<string, SiteRoutesGroup> = {}
  private static _sortedGroups: SiteRoutesGroup[] = []
  private static _isInitialized: boolean = false

  private static init(fromCache = true) {
    if (SiteRotesReader._isInitialized && fromCache) {
      return
    }
    SiteRotesReader._list = []
    SiteRotesReader._entityMap = {}
    SiteRotesReader._namesMap = {}
    SiteRotesReader._groupsMap = {}
    SiteRotesReader._sortedGroups = []
    SiteRotesReader._isInitialized = false

    /* sort */
    const groups = SiteRoutesGroups.groups || []
    groups.forEach((group) => {
      group.routes = [...group.routes].sort((a, b) => {
        return a.config.menuPriority - b.config.menuPriority
      })
    })
    SiteRotesReader._sortedGroups = groups.sort((a, b) => {
      return a.menuPriority - b.menuPriority
    })

    /* render and set cache */
    SiteRotesReader._sortedGroups.forEach((group) => {
      SiteRotesReader._groupsMap[group.id] = group
      group.routes.forEach((route) => {
        if (route.config.isRemoved) {
          return
        }
        if (!route.config.menuIdGroup) {
          route.setMenuIdGroup(group.id)
        }
        if (route.detailsRoute) {
          if (!route.detailsRoute.config.menuIdGroup) {
            route.detailsRoute.setMenuIdGroup(group.id)
          }
          if (!route.detailsRoute.route.name) {
            route.detailsRoute.setName(`${route.route.name}-details`)
          }
          if (!route.detailsRoute.config.detailsParentRouteName) {
            route.detailsRoute.config.detailsParentRouteName = route.route.name
          }
          if (!route.config.detailsRouteName) {
            route.config.detailsRouteName = route.detailsRoute.route.name
          }
          if (!route.detailsRoute.route.path) {
            route.detailsRoute.setPath(`${route.route.path}/:id(\\d+|new)`)
          }
          if (!route.detailsRoute.route.meta.basicSeo) {
            route.detailsRoute.route.meta.basicSeo = {
              title: '',
              description: '',
              defaultVariables: {
                detailsLabel: ''
              }
            }
          }
          if (!route.detailsRoute.route.meta.basicSeo.title) {
            route.detailsRoute.route.meta.basicSeo.title = `#{id} : {detailsLabel}`
          }
          if (!route.detailsRoute.route.meta.basicSeo.description) {
            route.detailsRoute.route.meta.basicSeo.description = `#{id} : {detailsLabel}`
          }
        }
        const pushedLen = SiteRotesReader._list.push(route)
        SiteRotesReader._namesMap[route.route.name] = pushedLen - 1
        if (route.detailsRoute?.route?.name) {
          const pushedLen = SiteRotesReader._list.push(route.detailsRoute)
          SiteRotesReader._namesMap[route.detailsRoute.route.name] = pushedLen - 1
        }
      })
    })
    /* set flag of cache */
    SiteRotesReader._isInitialized = true
  }

  static getList(fromCache = true) {
    SiteRotesReader.init(fromCache)
    return this._list
  }

  static getEntityMap(fromCache = true) {
    // TODO
    SiteRotesReader.init(fromCache)
    return this._entityMap
  }

  static getNamesMap(fromCache = true) {
    SiteRotesReader.init(fromCache)
    return this._namesMap
  }

  static getRouteConfigByName(name: string, fromCache = true) {
    SiteRotesReader.init(fromCache)
    return SiteRotesReader._list?.[SiteRotesReader._namesMap[name]] || new RoutesConfigurator()
  }

  static getGroupedRotes(fromCache = true) {
    SiteRotesReader.init(fromCache)
    return SiteRotesReader._sortedGroups
  }

  static getGroupById(name: string, fromCache = true) {
    SiteRotesReader.init(fromCache)
    return SiteRotesReader._groupsMap?.[name] || null
  }
}
