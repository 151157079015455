<script setup lang="ts">
import type { FormInstance } from 'element-plus'

interface Props {
  form?: Record<string, any>
  crudExternalErrors?: Record<string, any>
  labelPosition?: 'left' | 'right' | 'top'
  size?: '' | 'large' | 'default' | 'small'
}
const props = withDefaults(defineProps<Props>(), {
  labelPosition: 'top',
  size: ''
})

const emit = defineEmits<{
  (event: 'submit', value: FormTypes.SubmitForm): void
  (event: 'validation-error', value: Record<string, any>): void
}>()
const elFormRef = ref<FormInstance>()
const crudInitForm = ref<Record<string, any>>({})
const crudForm = props.form ? ref(props.form) : ref<Record<string, any>>({})
const crudExternalErrors = props.crudExternalErrors ? ref(props.crudExternalErrors) : ref<Record<string, any>>({})

provide('crudForm', crudForm)
provide('crudInitForm', crudInitForm)
provide('crudExternalErrors', crudExternalErrors)
provide('crudFormRefer', elFormRef)

async function submit() {
  const response = {
    valid: false
  }
  if (elFormRef.value) {
    await elFormRef.value.validate((valid, fields) => {
      response.valid = valid
      if (response.valid) {
        Object.keys(crudExternalErrors.value).forEach((key) => {
          crudExternalErrors.value[key] = ''
        })
        emit('submit', { form: crudForm.value, formRefer: elFormRef.value, externalErrors: crudExternalErrors.value } as FormTypes.SubmitForm)
      } else {
        emit('validation-error', fields || {})
      }
    })
  }
}
</script>

<template>
  <el-form
    ref="elFormRef"
    v-bind="$attrs"
    :model="crudForm"
    :label-position="labelPosition"
    :size="size"
  >
    <el-row>
      <el-col class="flex flex-wrap">
        <slot
          :form="crudForm"
          :external-errors="crudExternalErrors"
          :form-refer="elFormRef"
          :submit="submit"
        />
      </el-col>
    </el-row>
  </el-form>
</template>

<style scoped lang="postcss">
.el-form.el-form--large:deep(.el-form-item) {
  .el-input__clear, .el-input__password {
    font-size: 18px !important;
  }
}
</style>
