import type { App } from 'vue'

interface AppRefer {
  ref: App | null
}

const globalAppInstance: AppRefer = {
  ref: null
}

export { globalAppInstance }
