import type { App } from 'vue'
import { ElMessage, ElMessageBox, ElNotification } from 'element-plus'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/notification/style/css'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'

export default {
  install: (Vue: App) => {
    const globalProperties = Vue.config.globalProperties
    globalProperties.$message = ElMessage
    globalProperties.$messageBox = ElMessageBox
    globalProperties.$notify = ElNotification
  }
}
