import type { RoutesData, SsgData } from '@/router/init/siteRouter.types'
import { SiteRotesReader } from '@/router/init/SiteRotesReader'
import { SiteRoutesGroups } from '@/router/init/SiteRoutesGroups'

export class SiteRoutesConfigurator {
  route: RoutesData = {
    children: [],
    path: '',
    name: '',
    component: undefined,
    meta: {}
  }

  detailsRoute: SiteRoutesConfigurator | undefined = undefined

  ssg: SsgData[] = []

  config = {
    isRemoved: false,
    isVisibleInMenu: true,
    menuLabel: '',
    menuLabelClass: '',
    menuIdGroup: '',
    menuIcon: '',
    menuIconClass: '',
    menuPriority: 0,
    entity: '',
    detailsRouteName: '',
    detailsParentRouteName: ''
  }

  constructor(name = '', path = '', component: RoutesData['component'] = undefined) {
    this.setName(name)
      .setPath(path)
      .setComponent(component)
  }

  /** set route data */

  setName(name: string) {
    this.route.name = name
    return this
  }

  setPath(path: string) {
    this.route.path = path
    return this
  }

  setComponent(component: RoutesData['component']) {
    this.route.component = component
    return this
  }

  /** set meta data */

  setPageTitle(title: string) {
    this.route.meta.basicSeo = { ...(this.route.meta.basicSeo), title }
    return this
  }

  setPageDescription(description: string) {
    this.route.meta.basicSeo = { ...(this.route.meta.basicSeo), description }
    return this
  }

  setPageImage(image: string) {
    this.route.meta.basicSeo = { ...(this.route.meta.basicSeo), image }
    return this
  }

  setPagePath(path: string) {
    this.route.meta.basicSeo = { ...(this.route.meta.basicSeo), path }
  }

  setPageBodyClass(bodyClass: string) {
    this.route.meta.bodyClass = bodyClass
    return this
  }

  setPageDefaultVariables(defaultVariables: Record<string, unknown>) {
    this.route.meta.basicSeo = { ...(this.route.meta.basicSeo), defaultVariables: { ...defaultVariables } }
    return this
  }

  /** set menu data */

  setMenuLabel(label: string) {
    this.config.menuLabel = label
    return this
  }

  setMenuClass(menuClass: string) {
    this.config.menuLabelClass = menuClass
    return this
  }

  setMenuIcon(icon: string) {
    this.config.menuIcon = icon
    return this
  }

  setMenuIconClass(iconClass: string) {
    this.config.menuIconClass = iconClass
    return this
  }

  setMenuPriority(priority: number) {
    this.config.menuPriority = priority
    return this
  }

  setIsVisibleInMenu(isVisibleInMenu: boolean) {
    this.config.isVisibleInMenu = isVisibleInMenu
    return this
  }

  setMenuIdGroup(groupIdGroup: string) {
    this.config.menuIdGroup = groupIdGroup
    if (this.detailsRoute) {
      this.detailsRoute.config.menuIdGroup = groupIdGroup
    }
    return this
  }

  /** set more config */

  setEntity(entity: string) {
    this.config.entity = entity
    return this
  }

  setIsRemoved(isRemoved: boolean) {
    this.config.isRemoved = isRemoved
    return this
  }

  /** details */

  /**
   *
   * @param detailsComponent - vue file of page; required;
   * @param detailsLabel - short description details page used in title or main bar; required;
   * @param title - page title template; default: '{id} : {detailsLabel}'
   */
  buildWithDetailsRoute(detailsComponent: RoutesData['component'], detailsLabel: string, title = '') {
    this.detailsRoute = new SiteRoutesConfigurator()
    this.detailsRoute
      .setIsVisibleInMenu(false)
      .setComponent(detailsComponent)
      .setPageTitle(title)
      .setPageDefaultVariables({ detailsLabel })
    return this
  }

  getDetailsRoute() {
    return this.detailsRoute
  }

  /** ssg */

  setSsgRoutesData(ssgRoutesData: SsgData[]) {
    this.ssg = ssgRoutesData
    return this
  }

  /** static */

  /** route builders */
  static newRoute() {
    return new SiteRoutesConfigurator()
  }

  static BuildRoute(name: string, path: string, component: RoutesData['component']) {
    return new SiteRoutesConfigurator(name, path, component)
  }

  static getSiteRoutesGroups() {
    return SiteRoutesGroups
  }

  static registerRoute(route: SiteRoutesConfigurator) {
    return SiteRoutesGroups
      .addGroup(String(route.route.name))
      .setShowOnlyRoutes(true)
      .setRoutes([route])
      .setMenuPriority(route.config.menuPriority)
  }

  static registerGroupOfRoutes(idGroup: string, routes: SiteRoutesConfigurator[] = []) {
    return SiteRoutesGroups
      .addGroup(idGroup)
      .setRoutes(routes)
  }

  static getSiteRoutesReader() {
    return SiteRotesReader
  }
}
