import IndexPage from '@/pages/index/IndexPage.vue'
import { SiteRoutesConfigurator } from '@/router/init/SiteRoutesConfigurator'

SiteRoutesConfigurator.registerRoute(
  SiteRoutesConfigurator
    .BuildRoute('dashboard', '/', IndexPage)
    .setPageTitle('Dashboard')
    .setMenuIcon('ri:home-6-line')
)
SiteRoutesConfigurator.registerGroupOfRoutes('settings')
  .setLabel('Settings')
  .setIcon('iconamoon:settings')
  .setRoutes(
    [
      SiteRoutesConfigurator
        .BuildRoute('settings-admin-users', '/settings/admin-users', () => import('@/pages/settings/adminUsers/List.vue'))
        .setPageTitle('Admin Panel Users')
        .setMenuLabel('Admin Users')
        .setMenuIcon('ph:user')
        .setEntity('AdminUserEntity')
        .buildWithDetailsRoute(() => import('@/pages/settings/adminUsers/Details.vue'), 'Admin user')
    ]
  )

export default SiteRoutesConfigurator
