import pkgLodash from 'lodash'

const { get, set } = pkgLodash
interface BasicPropsType {
  form?: Record<string, any>
  externalErrors?: Record<string, any>
  apiFieldName: string
  label?: string | boolean
  rules?: any[]
  required?: boolean
  size?: '' | 'large' | 'default' | 'small'
  paddingClass?: string
  placeholder?: string
  initValue?: any
}
const BasicPropsDefaults = {
  apiFieldName: '',
  rules: () => [],
  required: false,
  size: '' as any,
  paddingClass: 'px-2',
  label: undefined
}

interface NumberPropsType {
  number?: boolean
}
const NumberPropsDefaults = {
  number: false
}

interface ResponsivePropsType {
  xs?: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
}
const ResponsivePropsDefaults = {
  xs: 24,
  sm: 24
}

function convertApiNamesToHuman(str: string) {
  if (str?.length > 2) {
    let newString = str
      .slice(1)
      .replace(/[A-Z]/g, letter => ` ${letter}`)
      .toLowerCase()
    newString = str.charAt(0).toUpperCase() + newString
    if (newString.slice(-2).toLowerCase() === 'id') {
      newString = newString.slice(0, -2)
    }
    return newString
  }
  return str
}

function getForm(propsForm: Record<string, any> | undefined) {
  return (propsForm ? ref(propsForm) : propsForm) || inject<Ref>('crudForm') || ref({})
}
function getFieldModel(form: object, apiFieldName: string) {
  return computed({
    get() {
      return get(form, apiFieldName)
    },
    set(val: any) {
      set(form, apiFieldName, val)
    }
  })
}

export {
  BasicPropsDefaults,
  convertApiNamesToHuman,
  get as gatByPath,
  getFieldModel,
  getForm,
  NumberPropsDefaults,
  ResponsivePropsDefaults,
  set as setByPath
}

export type {
  BasicPropsType,
  NumberPropsType,
  ResponsivePropsType
}
