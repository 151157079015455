import type { NotificationOptions } from 'element-plus'
import { useDark, useDocumentVisibility, useToggle } from '@vueuse/core'
import axios, { AxiosError } from 'axios'
import pkgLodash from 'lodash'

const { cloneDeep } = pkgLodash

const isProd = import.meta.env?.VITE_PROD === 'on'

function showError(elPlusErrorConfig: Partial<NotificationOptions> | null = null) {
  const defaultConfig = {
    title: 'Error',
    type: 'warning',
    customClass: 'child-inherit-colors text-red-600 z-[999999]',
    message: 'Oops something went wrong',
    duration: 9000
  }
  appUtilsPlugin.getAppGlobalProperties()?.$notify({ ...defaultConfig, ...elPlusErrorConfig })
}

function catchError(error: Error | boolean | AxiosError) {
  if (error instanceof AxiosError) {
    appUtilsPlugin.getAppGlobalProperties()?.$notify({
      title: 'Error',
      type: 'warning',
      customClass: 'child-inherit-colors text-red-600 z-[999999]',
      message: `${error?.response?.data?.message || error?.response?.data?.errors?.[0] || error}`,
      duration: 9000
    })
  } else if (error instanceof Error) {
    console?.error?.(error)
  }
}

const triggerRef = ref(0)
const isVisibleDocument = useDocumentVisibility()
const triggerApi = {
  triggerRef,
  callTrigger: (delay = 0) => {
    if (delay) {
      setTimeout(() => {
        triggerRef.value = ++triggerRef.value % 10
      }, delay)
    } else {
      triggerRef.value = ++triggerRef.value % 10
    }
  },
  initTriggerApi: () => {
    const isSsr = import.meta.env.SSR
    if (!isSsr) {
      checkBuildVersion(true)
      const timeout = localStorage?.getItem?.('devtimeout') ? Number.parseInt(String(localStorage?.getItem?.('devtimeout'))) : 60
      if (timeout !== 60) {
        // eslint-disable-next-line no-alert
        alert?.('devtimeout ENABLED !!!!!!!!!!')
      }
      const intervalID = setInterval(() => {
        if (isVisibleDocument.value !== 'hidden') {
          checkBuildVersion()
          triggerApi.callTrigger()
        }
      }, timeout * 1000)

      onUnmounted(() => {
        if (intervalID) {
          clearInterval(intervalID)
        }
      })
    }
  }
}

function checkBuildVersion(init = false) {
  const isSsr = import.meta.env.SSR
  const isDev = import.meta.env.DEV
  const host = import.meta.env.VITE_HOST || ''
  if (!isSsr && !isDev) {
    let deployVersion = localStorage?.getItem?.('deploy-version')
    if (deployVersion && deployVersion?.length > 50) {
      deployVersion = ''
      localStorage.setItem?.('deploy-version', deployVersion)
    }

    const axiosInst = axios.create()
    axiosInst.get(`${host}/build.txt`)
      .then((data) => {
        const buildTxt = data?.data
        if (buildTxt && buildTxt?.length < 50) {
          if (!init) {
            if (buildTxt && deployVersion && buildTxt !== deployVersion) {
              appUtilsPlugin.getAppGlobalProperties()?.$notify({
                title: 'New version of the store is available',
                type: 'warning',
                customClass: 'child-inherit-colors text-orange-400 z-[999999]',
                dangerouslyUseHTMLString: true,
                message: `<div class="text-left">It is recommended to immediately <a class="text-gs-main-color underline cursor-pointer hover:text-orange-600 active:text-orange-500" onclick="location?.reload?.()" >refresh store</a>.</div>`,
                duration: 58000
              })
            }
          } else {
            localStorage?.setItem?.('deploy-version', buildTxt)
          }
        }
      })
      .catch((error) => {
        console?.error(error)
      })
  }
}

function getFluxabeeApiUrl(endpoint: string) {
  const apiHost = isProd ? import.meta.env.VITE_FLUXABEE_API_PROD : import.meta.env.VITE_FLUXABEE_API_DEV
  return `${apiHost}/${endpoint}/`
}
export const isDark = useDark({ valueDark: 'dark' })
export const toggleDark = useToggle(isDark)

const siteUtils = {
  catchError,
  showError,
  triggerApi,
  isVisibleDocument,
  cloneDeep,
  getFluxabeeApiUrl,
  isProd,
  isDark,
  toggleDark
}

export { siteUtils }
